import * as React from 'react';
import Config from "../Config";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";

export interface Admin {

  adminId: string;
  password: string;
  maxActions: number;
  level: number;
  created: number;
}

interface ContextType {
  admin: Admin | null;
  setAdmin: (user: Admin | null) => void;
  adminLoaded: boolean;
  setAdminLoaded: (adminLoaded: boolean) => void;
}

export const UserContext = React.createContext<ContextType | null>(null);

export const UserProvider: React.FC = ({ children }) => {
  const [ admin, setAdmin ] = React.useState<Admin | null>(null);
  const [ adminLoaded, setAdminLoaded ] = useState<boolean>(false);

  useEffect(() => {
    fetch(`${Config.apiUrl}/admin/v2/auth/session`, {
      mode: 'cors',
      credentials: 'include'
    })
        .then((response) => response.json())
        .then((response) => {
          if (response.error)
              toast.error("You must be logged in!");
          else
              setAdmin(response.data);

            setAdminLoaded(true);
        })
        .catch((e) => toast.error("You must be logged in!"));
  }, []);

  return <UserContext.Provider value={{ admin, setAdmin, adminLoaded, setAdminLoaded }}>
    {children}
  </UserContext.Provider>;
}

export const useUserContext = (): ContextType => {
  const context = React.useContext(UserContext);

  if (context == null) {
    throw new Error('useUserContext must be used within a UserProvider');
  }

  return context;
}

