import { FC } from 'react';

interface HandbookQA {
    question: string;
    answer: string;
}

const Handbook:FC = () => {

    const questions: HandbookQA[] = [
        {
            question: "What is considered as \"Cheating\"?",
            answer: "Cheating is when a user performs typing actions without them physically typing on their keyboard. Examples of this is: Copy Paste, External Scripts, 3rd Party Software, Binding Keyboard layouts to Map Whole Words intentionally"
        },
        {
            question: "What is considered as \"Terms of Service / Other Violations\"?",
            answer: "Any form of harassment, bullying, hate speech, threatening, and spamming."
        },
        {
            question: "How are Keystroke logs in matches supposed to be read?",
            answer: "When viewing a match's keystroke logs, the most important thing to note is the order of the logs. The order is the letter followed by the milliseconds it took to type the next keystroke."
        },
        {
            question: "Some users logs show as 0ms for several instances, are they cheating?",
            answer: "In these instances please have a second moderator to review with you, in most cases it is not uncommon for keystrokes to register as 0ms. The best way to separate the difference between cheating and lag is by seeing if the next keystroke after the string of 0ms keystrokes are a big number in the thousands (or higher than their average keystroke delay). If that is the case, then they are likely not cheating however if the next keystroke registers anything under <250ms then it is plausible for cheating."
        },
        {
            question: "It says that my usage limit is exceeded, what does that mean?",
            answer: "Thank you for being so involved! As a security measure we enforce a request limit on accounts to avoid the potentials of power-tripping behavior, it will reset every day."
        }
    ];

    return (
        <div>
            <h1 className={"text-orange-400"}>Moderator Handbook</h1>
            <p className={"pt-3 pb-8"}>
                Welcome to the Moderator handbook, this handbook will mostly be very basic and answers some basic information on cheating and other measures or limitations that the control panel has.
            </p>
            <h2><span className={"text-red-400"}>Important</span> Notice</h2>
            <p className={"pt-3 pb-8"}>
                This ACP is <strong>not</strong> meant to be shown publicly to any users that aren't a Site Moderator. Any form of advertising in the panel done maliciously will result in immediate termination of your Keymash Moderator status and your Keymash account.
                <br/><br/>
                In addition to this, any gross misuse of these tools will result in a permanent ban from Keymash
            </p>
            <h2>Judgement Calls</h2>
            <p className={"pt-3 pb-8"}>
                There will be a lot of Moderators that will be new to these tools, some of you moderators will not know how to understand how Match keystroke logs work. Or understand what constitutes as a ban, we hope that some of the information provided here will help and if there is any suggestions on improving this content then please let us know.
                <br/><br/>
                If you are stuck on a player in terms of deciding if they should be banned or have another permission changed then please do not fear to contact any other Moderator.
            </p>
            <h2>Basic Information / FAQ</h2>
            <div className={"pt-3 pb-8"}>
                {questions.map((item) => (
                    <div key={item.question} className={"box-content"}>
                        <div className={"text-white font-semibold"}>{item.question}</div>
                        <div className={"text-gray-300 text-sm"}>{item.answer}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Handbook;
