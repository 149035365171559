import {FC, FormEvent, useCallback, useEffect, useState} from "react";
import Config from "../../../Config";
import {toast} from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { TournamentData } from "../../../interfaces/TournamentInterface"; 
import { Redirect } from "react-router-dom";

interface IProps {
    match: {
        params?: {
            search: string;
        }
    }
}

const Tournaments:FC<IProps> = (props) => {
    const search = String(props.match.params?.search || '');
    const [ redirect, setRedirect ] = useState<string>('');
    const [ searchInput, setSearchInput ] = useState<string>('');

    const [ results, setResults ] = useState<TournamentData[]>([]);
    const [ currentItem, setCurrentItem ] = useState<string | null>(null);

    const [ tournamentId, setTournamentId ] = useState<string>('');
    const [ name, setName ] = useState<string>('');
    const [ info, setInfo ] = useState<string>('');
    const [ rules, setRules ] = useState<string>('');
    const [ bracket, setBracket ] = useState<string | null>(null);
    const [ qualifier, setQualifier ] = useState<number>(0);
    const [ qualifierSort, setQualifierSort ] = useState<string>('');
    const [ qualifierCutoff, setQualifierCutoff ] = useState<number>(0);
    const [ recentWPMTotal, setRecentWPMTotal ] = useState<number>(0);
    const [ textType, setTextType ] = useState<number>(0);
    const [ hidden, setHidden ] = useState<number>(0);
    const [ startTime, setStartTime ] = useState<number>(0);
    const [ endTime, setEndTime ] = useState<number>(0);

    const [ modal, setModal ] = useState<boolean>(false);

    const resetAllFields = () => {
        setTournamentId('');
        setName('');
        setInfo('');
        setRules('');
        setBracket(null);
        setQualifier(0);
        setQualifierSort('');
        setQualifierCutoff(0);
        setRecentWPMTotal(0);
        setTextType(0);
        setHidden(0);
        setStartTime(0);
        setEndTime(0);
    }

    const fetchData = useCallback(() => {
        fetch(`${Config.apiUrl}/admin/v2/tournaments/list?search=${search || ''}`, {
            mode: 'cors',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error)
                    toast.error(response.error);
                else
                    setResults(response.data);
            })
            .catch(() => toast.error(`Unknown error occurred, please try again later!`));
    }, [ search ]);

    const handleEdit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        fetch(`${Config.apiUrl}/admin/v2/tournaments/update`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                filterTournament: currentItem,
                tournamentId: tournamentId, 
                name, 
                info, 
                rules, 
                bracket, 
                qualifier, 
                qualifierSort, 
                qualifierCutoff, 
                recentWPMTotal, 
                textType, 
                hidden, 
                startTime, 
                endTime 
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error)
                    toast.error(response.error);
                else {
                    setResults((oldData) => {
                        let i;
                        const length = oldData.length;
                        for (i = 0; i < length; i++) {
                            if (oldData[i].tournamentId === currentItem) {
                                oldData[i].tournamentId = tournamentId;
                                oldData[i].name = name;
                                oldData[i].info = info;
                                oldData[i].rules = rules;
                                oldData[i].bracket = bracket;
                                oldData[i].qualifier = qualifier;
                                oldData[i].qualifierSort = qualifierSort;
                                oldData[i].qualifierCutoff = qualifierCutoff;
                                oldData[i].recentWPMTotal = recentWPMTotal;
                                oldData[i].textType = textType;
                                oldData[i].hidden = hidden;
                                oldData[i].startTime = startTime;
                                oldData[i].endTime = endTime;
                            }
                        }

                        return [ ...oldData ];
                    });
                    toast.success('Updated');
                }
            })
            .catch(() => toast.error(`Unknown error occurred, please try again later!`));
    }

    const handleCreate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        fetch(`${Config.apiUrl}/admin/v2/tournaments/create`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ tournamentId, name, info, rules, bracket, qualifier, qualifierSort, qualifierCutoff, recentWPMTotal, textType, hidden, startTime, endTime })
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error)
                    toast.error(response.error);
                else {
                    fetchData();
                    toast.success('Updated');
                }
            })
            .catch(() => toast.error(`Unknown error occurred, please try again later!`));
    }

    useEffect(() => {
        fetchData();
    }, [ fetchData ]);

    const renderFields = (
        <>
            <div className="col-span-full">
                <label className={"text-white font-semibold text-sm"}>Tournament Id (optional)</label>
                <input className="form-general w-full block mb-4" type={"text"} onChange={(e) => setTournamentId(e.target.value)} placeholder={"Tournament Id"} value={tournamentId} />
            </div>

            <div className="col-span-1">
                <label className={"text-white font-semibold text-sm"}>Name</label>
                <input className="form-general w-full block mb-4" type={"text"} onChange={(e) => setName(e.target.value)} placeholder={"Name"} value={name} />
            </div>

            <div className="col-span-1">
                <label className={"text-white font-semibold text-sm"}>Bracket</label>
                <input className="form-general w-full block mb-4" type={"text"} onChange={(e) => setBracket(e.target.value)} placeholder={"Bracket"} value={bracket || ''} />
            </div>

            <div className="col-span-1">
                <label className={"text-white font-semibold text-sm"}>Information</label>
                <textarea className="form-general w-full block mb-4" onChange={(e) => setInfo(e.target.value)} placeholder={"Info"} rows={3} value={info} />
            </div>

            <div className="col-span-1">
                <label className={"text-white font-semibold text-sm"}>Rules</label>
                <textarea className="form-general w-full block mb-4" onChange={(e) => setRules(e.target.value)} placeholder={"Rules"} rows={3} value={rules} />
            </div>

            <div className="col-span-1">
                <label className={"text-white font-semibold text-sm"}>Qualifier</label>
                <select className="form-general w-full block mb-4" onChange={(e) => setQualifier(parseInt(e.target.value))} value={qualifier}>
                    <option value="0" defaultChecked={qualifier === 0}>No</option>
                    <option value="1" defaultChecked={qualifier === 1}>Yes</option>
                </select>
            </div>

            <div className="col-span-1">
                <label className={"text-white font-semibold text-sm"}>Qualifier Cutoff</label>
                <select className="form-general w-full block mb-4" onChange={(e) => setQualifierCutoff(parseInt(e.target.value))} value={qualifierCutoff}>
                    {[...Array(33)].map((_item, i) => i > 0 && <option key={i} value={i} defaultChecked={qualifierCutoff === i}>{i}</option>)}
                </select>
            </div>

            <div className="col-span-1">
                <label className={"text-white font-semibold text-sm"}>Leaderboard Sort</label>
                <select className="form-general w-full block mb-4" onChange={(e) => setQualifierSort(e.target.value)} value={qualifierSort}>
                    <option value="maxWPM" defaultChecked={qualifierSort === 'maxWPM'}>Highest WPM</option>
                    <option value="avgWPM" defaultChecked={qualifierSort === 'avgWPM'}>Average WPM</option>
                    <option value="matchesTotal" defaultChecked={qualifierSort === 'matchesTotal'}>Matches Total</option>
                </select>
            </div>

            <div className="col-span-1">
                <label className={"text-white font-semibold text-sm"}>Recent WPM Total (requires Average WPM)</label>
                <select className="form-general w-full block mb-4" onChange={(e) => setRecentWPMTotal(parseInt(e.target.value))} value={recentWPMTotal}>
                    {[...Array(21)].map((_item, i) => i > 0 && <option key={i} value={i} defaultChecked={recentWPMTotal === i}>{i}</option>)}
                </select>
            </div>

            <div className="col-span-1">
                <label className={"text-white font-semibold text-sm"}>Text Type</label>
                <select className="form-general w-full block mb-4" onChange={(e) => setTextType(parseInt(e.target.value))} value={textType}>
                    <option value="0" defaultChecked={textType === 0}>Random</option>
                    <option value="1" defaultChecked={textType === 1}>Quotes</option>
                    <option value="2" defaultChecked={textType === 1}>Dictionary</option>
                </select>
            </div>

            <div className="col-span-1">
                <label className={"text-white font-semibold text-sm"}>Private (doesn't appear to the public)</label>
                <select className="form-general w-full block mb-4" onChange={(e) => setHidden(parseInt(e.target.value))} value={hidden}>
                    <option value="0" defaultChecked={hidden === 0}>No</option>
                    <option value="1" defaultChecked={hidden === 1}>Yes</option>
                </select>
            </div>

            <div className="col-span-1">
                <label className={"text-white font-semibold text-sm"}>Start Time (UNIX no milliseconds)</label>
                <input className="form-general w-full block mb-4" type={"text"} onChange={(e) => setStartTime(parseInt(e.target.value, 10))} placeholder={"Start Time"} value={startTime || ''} />
            </div>

            <div className="col-span-1">
                <label className={"text-white font-semibold text-sm"}>End Time (UNIX no milliseconds)</label>
                <input className="form-general w-full block mb-4" type={"text"} onChange={(e) => setEndTime(parseInt(e.target.value, 10))} placeholder={"End Time"} value={endTime || ''} />
            </div>
        </>
    )

    console.log({ tournamentId, name, info, rules, bracket, qualifier, qualifierSort, qualifierCutoff, recentWPMTotal, textType, hidden, startTime, endTime })

    return (
        <>
            {redirect && <Redirect to={redirect} />}
            <div className={`fixed inset-0 w-full flex h-screen z-50 bg-black bg-opacity-50 ${modal ? 'pointer-events-auto opacity-100' : 'opacity-0 pointer-events-none'} transition ease-in-out duration-300`}>
                <div className="w-full md:max-w-screen-md w-full px-10 m-auto">
                    <div className="bg-gray-700 rounded-lg shadow-lg p-8 relative">
                        <button type="button" className="focus:outline-none absolute top-3 right-3" onClick={() => setModal(false)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                        
                        <h2 className={"pb-4"}>Create Tournament</h2>
                        <form method={"post"} onSubmit={handleCreate}>
                            <div className={"grid grid-cols-2 gap-x-4 gap-y-2"}>
                                {renderFields}
                                
                                <div className={"col-span-full"}>
                                    <button type={"submit"} className={"btn btn-default btn-blue rounded ml-auto"}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"flex flex-wrap"}>
                <div className={"w-full lg:w-auto"}>
                    <h1>Tournaments</h1>
                </div>
                <div className={"w-full lg:w-auto lg:ml-auto"}>
                    <form onSubmit={() => setRedirect(`/tournaments/${searchInput}`)}>
                        <input type={"text"} className={"form-general rounded"} placeholder={"Search"} defaultValue={search} onChange={(e) => setSearchInput(e.target.value)} />
                    </form>
                </div>
                <div className={"w-full lg:w-auto lg:pl-4"}>
                    <button type="button" className="btn btn-lg btn-default rounded-lg" onClick={() => { resetAllFields(); setModal(true); }}>Create</button>
                </div>
            </div>
            <div className={"table-head mt-6"}>
                <div className={"w-12"}>#</div>
                <div className={"w-80 mr-auto"}>Slug</div>
                <div className={"w-24"}>Players #</div>
            </div>
            {results.map((item, index) => (
                <div>
                    <button type={"button"} onClick={() => { 
                        console.log(item);
                        setCurrentItem(currentItem === item.tournamentId ? null : item.tournamentId); 
                        setTournamentId(item.tournamentId);
                        setName(item.name); 
                        setInfo(item.info);
                        setRules(item.rules);
                        setBracket(item.bracket);
                        setQualifier(item.qualifier);
                        setQualifierSort(item.qualifierSort);
                        setQualifierCutoff(item.qualifierCutoff);
                        setRecentWPMTotal(item.recentWPMTotal);
                        setTextType(item.textType);
                        setHidden(item.hidden);
                        setStartTime(item.startTime);
                        setEndTime(item.endTime);
                    }} className={"flex w-full text-left border-b-2 border-gray-700 text-base bg-gray-800 hover:bg-gray-700 transition ease-in-out duration-300 py-2 px-4"}>
                        <div className={"w-12"}>{index + 1}</div>
                        <div className={"w-96 mr-auto truncate"}>{item.name}</div>
                        <div className={"w-24"}>{item.totalPlayers.toLocaleString()}</div>
                    </button>
                    {currentItem === item.tournamentId && (
                        <div className={"bg-gray-700 p-6"}>
                            <h2 className={"pb-4"}>Modify Tournaments</h2>
                            <form method={"post"} onSubmit={handleEdit}>
                                <div className={"grid grid-cols-2 gap-x-4 gap-y-2"}>
                                    {renderFields}

                                    <div className={"col-span-full"}>
                                        <button type={"submit"} className={"btn btn-default btn-blue rounded ml-auto"}>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            ))}

        </>
    )
}

export default Tournaments;
