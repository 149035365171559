import { FC } from 'react';

const ErrorNotFound:FC = () => {
    return (
        <div className={"flex h-screenPadding"}>
            <div className={"m-auto text-center text-3xl font-bold text-white"}>
                <h1>404 Not Found</h1>
                <p className={"text-lg pt-5"}>
                    The page you are trying to access does not exist, please try again later
                </p>
            </div>
        </div>
    )
}

export default ErrorNotFound;
