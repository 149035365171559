import React from 'react';
import { UserProvider } from './User.context';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from "./AppRouter";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <ToastContainer />
      <UserProvider>
        <AppRouter />
      </UserProvider>
    </Router>
  );
}

export default App;
