import {FC, FormEvent, useCallback, useEffect, useState} from "react";
import Config from "../../../Config";
import {toast} from "react-toastify";
import { RedemptionCodeData } from "../../../interfaces/RedemptionCodeInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Redemption: FC = () => {
    const [ results, setResults ] = useState<RedemptionCodeData[]>([]);

    const [ modal, setModal ] = useState<boolean>(false);
    const [ redeemed, setRedeemed ] = useState<boolean>(true);
    const [ type, setType ] = useState<string>('currency');
    const [ value, setValue ] = useState<string | number>(500);

    const fetchData = useCallback(async () => {
        fetch(`${Config.apiUrl}/admin/v2/code/list?startNum=0&limit=100${redeemed ? `&redeemed=true` : ''}`, {
            mode: 'cors',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error)
                    toast.error(response.error);
                else
                    setResults(response);
            })
            .catch(() => toast.error(`Unknown error occurred, please try again later!`));
    }, [ redeemed ]);

    const handleCreate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        fetch(`${Config.apiUrl}/admin/v2/code/create`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ type, value })
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error)
                    toast.error(response.error);
                else {
                    setRedeemed(false);
                    setModal(false);
                    toast.success('Created');
                }
            })
            .catch(() => toast.error(`Unknown error occurred, please try again later!`));
    }

    useEffect(() => {
        fetchData();
    // eslint-dsiable-next-line react-hooks/exhaustive-deps
    }, [ ]);

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ redeemed ]);

    const fields = [
        { type: 'select', name: 'type', label: 'Type', options: [{ label: 'Currency', value: 'currency' }, { label: 'Item', value: 'item' }], onUpdate: (v: string) => setType(String(v)) },
        { type: 'text', name: 'value', label: 'Value', onUpdate: (v: string | number) => setValue(v) },
    ]

    return (
        <>
            {/* Modal */}
            <div className={`z-50 inset-0 h-screen flex fixed bg-black bg-opacity-50 ${modal ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition ease-in-out duration-300`}>
                <form method="post" className={"px-10 m-auto w-full lg:w-128"} onSubmit={handleCreate}>
                    <div className={"bg-gray-800 rounded-2xl p-8 relative"}>
                        <button type="button" className="absolute top-4 right-4" onClick={() => setModal(!modal)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                        <div className="grid grid-cols-1 gap-4">
                            {fields.map((field) => field.type !== 'select' ? (
                                <div key={field.name}>
                                    <label className="block w-full mb-2">{field.label}</label>
                                    <input type={field.type} name={field.name} className={"w-full rounded-full bg-gray-700 px-4 py-2"} onChange={(e) => field.onUpdate(String(e.target.value))} />
                                </div>
                            ) : (
                                <div key={field.name}>
                                    <label className="block w-full mb-2">{field.label}</label>
                                    <select name={field.name} className={"w-full rounded-full bg-gray-700 px-4 py-2"} onChange={(e) => field.onUpdate(String(e.target.value))}>
                                        <option value="">Select</option>
                                        {field.options?.map((option) => (
                                            <option value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                </div>
                            ))}
                        </div>
                        <button type={"submit"} className={"mt-4 btn-sm btn-blue rounded-full"}>
                            Submit
                        </button>
                    </div>
                </form>
            </div>

            {/* Page */}
            <div className={"flex flex-wrap items-center"}>
                <div className={"w-full lg:w-auto"}>
                    <h1>Redemption</h1>
                </div>
                <div className={"w-full lg:w-auto lg:ml-auto"}>
                    <button type="button" className={`btn btn-default`} onClick={() => setRedeemed(!redeemed)}>{redeemed ? 'Not Redeemed' : 'Redeemed'}</button>
                </div>
                <div className={"w-full lg:w-auto ml-4"}>
                    <button type="button" className={`btn btn-orange`} onClick={() => setModal(!modal)}>Create</button>
                </div>
            </div>
            <div className={"table-head mt-6"}>
                <div className={"w-12"}>#</div>
                <div className={"w-80 mr-auto"}>Code</div>
                <div className={"ml-auto w-40 px-4"}>Type</div>
                <div className={"w-40 px-4"}>Item / Value</div>
                <div className={"w-32 px-4"}>Claimed</div>
            </div>
            {results.map((item, index) => (
                <div>
                    <div className={"flex w-full text-left border-b-2 border-gray-700 text-base bg-gray-800 hover:bg-gray-700 transition ease-in-out duration-300 py-2 px-4"}>
                        <div className={"w-12"}>{index + 1}</div>
                        <div className={"w-96 truncate"}>{item.code}</div>
                        <div className={"ml-auto w-40 px-4"}>{item.type}</div>
                        <div className={"w-40 px-4 truncate"}>{item.value}</div>
                        <div className={"w-32 px-4"}>{item?.playerId ? 'Claimed' : 'Not Claimed'}</div>
                    </div>
                </div>
            ))}
            
            {results?.length === 0 && (
                <div className="flex flex-col items-center justify-center h-96 w-full bg-gray-800 rounded-xl mt-8">
                    <h5>No results found</h5>
                </div>
            )}
        </>
    )
}

export default Redemption;
