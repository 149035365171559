import React from 'react';

export interface DashboardStatisticProps {
  title: string;
  desc: string;
  value: string;
}

const DashboardStatistic: React.FC<DashboardStatisticProps> = (props) => {
  const { title, desc, value } = props;

  return (
    <div className={"box-stat"}>
      <h4 className={"text-gray-400"}>{title}</h4>
      <small className="mb-2 block text-gray-500">{desc}</small>
      <div className="text-white text-5xl font-bold">{value ? value : '-'}</div>
    </div>
  );
};

export default DashboardStatistic;
