import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {NavLink} from 'react-router-dom';

export interface SidebarRouteProps {
  icon: IconDefinition;
  name: string;
  path: string;
  disabled?: boolean;
}

const SidebarRoute: React.FC<SidebarRouteProps> = (props) => {
  const { icon, name, path, disabled } = props;

  return (
    <NavLink to={path} exact={path === '/'} data-tip={name} activeClassName="text-orange-400 hover:text-orange-400 bg-gray-800" className={`${disabled ? 'opacity-50 pointer-events-none' : ''} flex my-auto text-gray-500 py-1.5 px-4 text-sm hover:bg-gray-800 rounded-full transition ease-in-out duration-300`}>
        <div className="w-4 text-right">
            <FontAwesomeIcon size="lg" icon={icon} />
        </div>
    </NavLink>
  ); 
};

export default SidebarRoute;
