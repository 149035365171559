import React, {FC} from "react";
import {useUserContext} from "./User.context";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Footer from './footer/Footer';
import Login from "./pages/login/Login";
import User from "./pages/user/User";
import ErrorNotFound from "./pages/404";
import Search from "./pages/search/Search";
import Handbook from "./pages/handbook/Handbook";
import Navbar from "./sidebar/Navbar";
import Loading from "./Loading";
import Texts from "./pages/texts/Texts";
import News from "./pages/news/News";
import ReactTooltip from 'react-tooltip';
import Tournaments from "./pages/tournaments/Tournaments";
import Redemption from "./pages/redemption/Redemption";

const AppRouter:FC = () => {
    const { admin, adminLoaded } = useUserContext();

    return (
        adminLoaded ? (
            admin ? (
                <>
                    <ReactTooltip place="bottom" />
                    <div className={"tw-container mt-24"}>
                        <Navbar />
                        <Switch>
                            <Route path={"/"} exact={true} component={Dashboard} />
                            <Route path={"/handbook"} component={Handbook} />
                            <Route path={"/user/:name"} component={User} />
                            <Route path={"/search/:q"} component={Search} />
                            <Route path={"/search"} component={Search} />
                            <Route path={"/texts"} component={Texts} />
                            <Route path={"/news"} component={News} /> 
                            <Route path={"/tournaments/:search"} component={Tournaments} />
                            <Route path={"/tournaments"} component={Tournaments} /> 
                            <Route path={"/redemption"} component={Redemption} />
                            <Route component={ErrorNotFound} />
                        </Switch>
                    </div>
                    <Footer />
                </>
            ) : (
                <Login />
            )
        ) : <Loading />
    )
}

export default AppRouter;
