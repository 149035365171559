import {FC, FormEvent, useEffect, useState} from "react";
import Config from "../../../Config";
import {toast} from "react-toastify";
import { NewsData } from "../../../interfaces/NewsInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const News: FC = () => {
    const [ results, setResults ] = useState<NewsData[]>([]);
    const [ currentItem, setCurrentItem ] = useState<string | null>(null);

    const [ content, setContent ] = useState<string>('');
    const [ title, setTitle ] = useState<string>('');

    const [ modal, setModal ] = useState<boolean>(false);

    const fetchData = () => {
        fetch(`${Config.apiUrl}/admin/v2/news/list`, {
            mode: 'cors',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error)
                    toast.error(response.error);
                else
                    setResults(response.data);
            })
            .catch(() => toast.error(`Unknown error occurred, please try again later!`));
    };

    const handleEdit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        fetch(`${Config.apiUrl}/admin/v2/news/update`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ title, content, slug: currentItem })
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error)
                    toast.error(response.error);
                else {
                    setResults((oldData) => {
                        let i;
                        const length = oldData.length;
                        for (i = 0; i < length; i++) {
                            if (oldData[i].slug === currentItem) {
                                oldData[i].title = title;
                                oldData[i].content = content;
                            }
                        }

                        return [ ...oldData ];
                    });
                    toast.success('Updated');
                }
            })
            .catch(() => toast.error(`Unknown error occurred, please try again later!`));
    }

    const handleCreate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        fetch(`${Config.apiUrl}/admin/v2/news/create`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ title, content })
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error)
                    toast.error(response.error);
                else {
                    fetchData();
                    toast.success('Updated');
                }
            })
            .catch(() => toast.error(`Unknown error occurred, please try again later!`));
    }

    useEffect(() => {
        fetchData();
    }, [ ]);

    return (
        <>
            <div className={`fixed inset-0 w-full flex h-screen z-50 bg-black bg-opacity-50 ${modal ? 'pointer-events-auto opacity-100' : 'opacity-0 pointer-events-none'} transition ease-in-out duration-300`}>
                <div className="w-full md:max-w-screen-md w-full px-10 m-auto">
                    <div className="bg-gray-700 rounded-lg shadow-lg p-8 relative">
                        <button type="button" className="focus:outline-none absolute top-3 right-3" onClick={() => setModal(false)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                        
                        <h2 className={"pb-4"}>Create Text</h2>
                        <form method={"post"} onSubmit={handleCreate}>
                            <div className={"grid grid-cols-1 gap-6"}>
                                <div>
                                    <label className={"text-white font-semibold text-sm"}>Title</label>
                                    <input className="form-general w-full block mb-4" type={"text"} onChange={(e) => setTitle(e.target.value)} placeholder={"Title"} value={title} />
                                </div>
                                <div>
                                    <label className={"text-white font-semibold text-sm"}>Content</label>
                                    <textarea className="form-general w-full block" onChange={(e) => setContent(e.target.value)} rows={8} placeholder={"Content"} value={content} />
                                </div>
                                <div className={"col-span-full"}>
                                    <button type={"submit"} className={"btn btn-default btn-blue rounded ml-auto"}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"flex flex-wrap"}>
                <div className={"w-full lg:w-auto"}>
                    <h1>News</h1>
                </div>
                <div className={"w-full lg:w-auto lg:ml-auto"}>
                    <button type="button" className="btn btn-sm btn-default rounded-lg" onClick={() => { setTitle(''); setContent(''); setModal(true); }}>Create</button>
                </div>
            </div>
            <div className={"table-head mt-6"}>
                <div className={"w-12"}>#</div>
                <div className={"w-80 mr-auto"}>Slug</div>
            </div>
            {results.map((item, index) => (
                <div>
                    <button type={"button"} onClick={() => { setCurrentItem(currentItem === item.slug ? null : item.slug); setTitle(item.title); setContent(item.content); }} className={"flex w-full text-left border-b-2 border-gray-700 text-base bg-gray-800 hover:bg-gray-700 transition ease-in-out duration-300 py-2 px-4"}>
                        <div className={"w-12"}>{index + 1}</div>
                        <div className={"w-96 truncate"}>{item.slug}</div>
                    </button>
                    {currentItem === item.slug && (
                        <div className={"bg-gray-700 p-6"}>
                            <h2 className={"pb-4"}>Modify Text</h2>
                            <form method={"post"} onSubmit={handleEdit}>
                                <div className={"grid grid-cols-1 gap-6"}>
                                    <div>
                                        <label className={"text-white font-semibold text-sm"}>Title</label>
                                        <input className="form-general w-full block mb-4" type={"text"} onChange={(e) => setTitle(e.target.value)} placeholder={"Title"} value={title} />
                                    </div>
                                    <div>
                                        <label className={"text-white font-semibold text-sm"}>Content</label>
                                        <textarea className="form-general w-full block" onChange={(e) => setContent(e.target.value)} rows={8} placeholder={"Content"} value={content} />
                                    </div>
                                    <div className={"col-span-full"}>
                                        <button type={"submit"} className={"btn btn-default btn-blue rounded ml-auto"}>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            ))}

        </>
    )
}

export default News;
