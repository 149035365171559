import {FC, FormEvent, useEffect, useState} from "react";
import Config from "../../../Config";
import {toast} from "react-toastify";
import {TextData} from "../../../interfaces/TextInterface";

const Texts: FC = () => {
    const [ results, setResults ] = useState<TextData[]>([]);
    const [ currentItem, setCurrentItem ] = useState<number | null>(null);
    const [ search, setSearch ] = useState<string>('');

    const [ content, setContent ] = useState<string>('');
    const [ source, setSource ] = useState<string>('');
    const [ author, setAuthor ] = useState<string>('');

    const fetchData = (searchTerm?: string) => {
        fetch(`${Config.apiUrl}/admin/v2/texts/list${searchTerm ? `?search=${searchTerm}` : ''}`, {
            mode: 'cors',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error)
                    toast.error(response.error);
                else
                    setResults(response);
            })
            .catch(() => toast.error(`Unknown error occurred, please try again later!`));
    };

    const handleEdit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        fetch(`${Config.apiUrl}/admin/v2/texts/update`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ source, content, author, textId: currentItem })
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error)
                    toast.error(response.error);
                else {
                    setResults((oldData) => {
                        let i;
                        const length = oldData.length;
                        for (i = 0; i < length; i++) {
                            if (oldData[i].textId === currentItem) {
                                oldData[i].content = content;
                                oldData[i].author = author;
                                oldData[i].source = source;
                            }
                        }

                        return [ ...oldData ];
                    });
                    toast.success('Updated');
                }
            })
            .catch(() => toast.error(`Unknown error occurred, please try again later!`));
    }

    useEffect(() => {
        fetchData();
    }, [ ]);

    return (
        <>
            <div className={"flex flex-wrap"}>
                <div className={"w-full lg:w-auto"}>
                    <h1>Texts</h1>
                </div>
                <div className={"w-full lg:w-auto lg:ml-auto"}>
                    <form onSubmit={(e) => { e.preventDefault(); fetchData(search); }}>
                        <input type={"text"} className={"form-default"} onChange={(e) => setSearch(e.target.value)} placeholder="Search" value={search} />
                    </form>
                </div>
            </div>
            <div className={"table-head mt-6"}>
                <div className={"w-12"}>#</div>
                <div className={"w-80 mr-auto"}>Content</div>
                <div className={"ml-auto w-40 px-4"}>Source</div>
                <div className={"w-40 px-4"}>Author</div>
                <div className={"w-40 px-4"}>Contributor</div>
            </div>
            {results.map((item, index) => (
                <div>
                    <button type={"button"} onClick={() => { setCurrentItem(currentItem === item.textId ? null : item.textId); setAuthor(item.author); setContent(item.content); setSource(item.source); }} className={"flex w-full text-left border-b-2 border-gray-700 text-base bg-gray-800 hover:bg-gray-700 transition ease-in-out duration-300 py-2 px-4"}>
                        <div className={"w-12"}>{index + 1}</div>
                        <div className={"w-96 truncate"}>{item.content}</div>
                        <div className={"ml-auto w-64 truncate px-4"}>{item.source}</div>
                        <div className={"w-40 px-4 truncate"}>{item.author}</div>
                        <div className={"w-40 px-4 truncate"}>{item.contributor}</div>
                    </button>
                    {currentItem === item.textId && (
                        <div className={"bg-gray-700 p-6"}>
                            <h2 className={"pb-4"}>Modify Text</h2>
                            <form method={"post"} onSubmit={handleEdit}>
                                <div className={"grid grid-cols-1 md:grid-cols-2 gap-6"}>
                                    <div>
                                        <label className={"text-white font-semibold text-sm"}>Content</label>
                                        <textarea className="form-general w-full block" onChange={(e) => setContent(e.target.value)} rows={4} placeholder={"Content"} value={content} />
                                    </div>
                                    <div>
                                        <label className={"text-white font-semibold text-sm"}>Source</label>
                                        <input className="form-general w-full block mb-4" type={"text"} onChange={(e) => setSource(e.target.value)} placeholder={"Source"} value={source} />

                                        <label className={"text-white font-semibold text-sm"}>Author</label>
                                        <input className="form-general w-full block" type={"text"} onChange={(e) => setAuthor(e.target.value)} placeholder={"Author"} value={author} />
                                    </div>
                                    <div className={"col-span-full"}>
                                        <button type={"submit"} className={"btn btn-default btn-blue rounded ml-auto"}>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            ))}

        </>
    )
}

export default Texts;
