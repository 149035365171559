import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FC} from "react";

const Loading:FC = () => {
    return (
        <div className={"flex h-screen"}>
            <div className={"m-auto"}>
                <FontAwesomeIcon icon={faSpinner} size={"3x"} spin />
            </div>
        </div>
    )
}

export default Loading;
