import { FC } from 'react';
import DashboardStatistics from './statistics/DashboardStatistics';

const Dashboard: FC = () => {
  return (
    <div>
      <DashboardStatistics/>
    </div>
  );
};

export default Dashboard;
