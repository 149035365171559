import { FC, useState, useEffect } from 'react';
import DashboardStatistic, { DashboardStatisticProps } from './DashboardStatistic';
import Config from "../../../../Config";
import {toast} from "react-toastify";

interface DashboardStatisticsProps {
    getNewPlayers: number;
    getMatches: number;
    getPlayerMatches: number;
}

const DashboardStatistics: FC = () => {

    const [ results, setResults ] = useState<DashboardStatisticsProps>({
        getNewPlayers: 0,
        getMatches: 0,
        getPlayerMatches: 0
    });

  useEffect(() => {
      fetch(`${Config.apiUrl}/admin/v2/dashboard`, {
          mode: 'cors',
          credentials: 'include',
      })
          .then((response) => response.json())
          .then((response) => {
              if (response.error)
                  toast.error(response.error);
              else
                  setResults(response);
          })
          .catch(() => toast.error(`Unknown error occurred, please try again later!`));
  }, []);

  const statistics: DashboardStatisticProps[] = [
    {
      title: 'New Users',
      desc: 'Number of users who played for the first time',
      value: `${results.getNewPlayers.toLocaleString()}`,
    },
    {
      title: 'Matches',
      desc: 'Number of matches that were created',
      value: `${results.getMatches.toLocaleString()}`,
    },
    {
      title: 'Players',
      desc: 'Number of players that participated in a match',
      value: `${results.getPlayerMatches.toLocaleString()}`,
    },
  ];

  return (
      <>
          <h3 className={"pb-3 "}>Statistics (Last 24 hours)</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {statistics.map(statistic => (
                  <DashboardStatistic key={statistic.title} {...statistic} />
              ))}
          </div>

      </>
  );
};

export default DashboardStatistics;
