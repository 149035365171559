import {FC} from 'react';
import Logo from "../logo/Logo";
import {Link} from "react-router-dom";
import SidebarRoute, {SidebarRouteProps} from "./SidebarRoute";
import {
    faBook,
    faCode,
    faHome, faNewspaper, faQuoteRight,
    faStickyNote,
    faTrophy,
    faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from '../User.context';

const Navbar:FC = () => {

    const { admin } = useUserContext();

    const mainRoutes: SidebarRouteProps[] = [
        {
            icon: faHome,
            path: '/',
            name: 'Home',
        },
        {
            icon: faBook,
            path: '/handbook',
            name: 'Handbook',
        },
    ];

    const userRoutes: SidebarRouteProps[] = [
        {
            icon: faUserFriends,
            path: '/search',
            name: 'User Search'
        },
        {
            icon: faQuoteRight,
            path: '/texts',
            name: 'Texts',
            disabled: (admin ? admin.level : 1) < 2
        },
        {
            icon: faTrophy,
            path: '/tournaments',
            name: 'Tournaments',
            disabled: (admin ? admin.level : 1) < 1337
        },
        {
            icon: faNewspaper,
            path: '/news',
            name: 'News',
            disabled: (admin ? admin.level : 1) < 1337
        },
        {
            icon: faCode,
            path: '/redemption',
            name: 'Redemption',
            disabled: (admin ? admin.level : 1) < 1337
        },
        {
            icon: faStickyNote,
            path: '/logs',
            name: 'Logs',
            disabled: true
        },
    ];

    return (
        <div className="fixed top-0 left-0 right-0 z-50 bg-gray-800 shadow">
            <div className="tw-container py-4">
                <div className={"flex"}>
                    <div className="w-1/2">
                        <Link to={"/"} className={"block w-12 lg:w-48 my-auto"}>
                            <Logo small />
                        </Link>
                    </div>
                    

                    <div className="w-1/2 my-auto">
                        <div className="flex space-x-2 justify-end">
                            {mainRoutes.map(route => <SidebarRoute key={route.name} {...route} /> )}
                            {userRoutes.map(route => <SidebarRoute key={route.name} {...route} /> )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar;
