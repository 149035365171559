import {FC, useState, useEffect, useCallback} from 'react';
import {Redirect, Link, useParams} from 'react-router-dom';
import {Player} from "../../../interfaces/UserInterface";
import Config from "../../../Config";
import {toast} from "react-toastify";

interface SearchProps {
    q: string;
};

const Search: FC = () => {
    const { q } = useParams<SearchProps>();

    const [ results, setResults ] = useState<Player[]>([]);
    const [ redirect, setRedirect ] = useState('');
    const [ search, setSearch ] = useState(q);

    const searchUser = useCallback(() => {
        setSearch(q);

        fetch(`${Config.apiUrl}/admin/v2/player/search?search=${q}`, {
            mode: 'cors',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error)
                    toast.error(response.error);
                else
                    setResults(response);
            })
            .catch(() => toast.error(`Unknown error occurred, please try again later!`));
    }, [ q ]);

    const toggleSearch = () => setRedirect(search);

    useEffect(() => {
        searchUser();
    }, [ searchUser ]);

    return (
        <>
            {redirect && <Redirect to={`/search/${redirect}`} />}
            {q ? (
                <div>

                    <div className={"flex flex-wrap"}>
                        <div className={"w-full lg:w-2/3"}>
                            <h1>Showing results for "<span className={"text-orange-400"}>{q}</span>".</h1>
                        </div>
                        <div className={"w-full lg:ml-auto lg:w-auto"}>
                            <form onSubmit={toggleSearch}>
                                <input type={"text"} className={"form-general rounded"} placeholder={"Search"} value={search} onChange={(e) => setSearch(e.target.value)} required />
                            </form>
                        </div>
                    </div>
                    <div className={"table-head mt-6"}>
                        <div className={"w-12"}>#</div>
                        <div className={"w-80"}>Name</div>
                        <div className={"w-32 ml-auto"}>Banned</div>
                        <div className={"w-32"}>Verified</div>
                        <div className={"w-32"}>Patreon</div>
                        <div className={"w-32"}>Staff</div>
                    </div>

                    {results.map((item, index) => (
                        <Link to={`/user/${item.name}-${item.discriminator}`} key={item.playerId} className={"table-item"}>
                            <div className={"w-12 font-semibold opacity-50"}>{index + 1}</div>
                            <div className={"w-80 normal-case"}>{item.name}#{item.discriminator}</div>
                            <div className={"w-32 ml-auto"}>{item.banned ? <span className={"text-red-600"}>Yes</span> : <span className={"text-green-600"}>No</span>}</div>
                            <div className={"w-32"}>{item.verified ? <span className={"text-green-600"}>Yes</span> : <span className={"text-blue-600"}>No</span>}</div>
                            <div className={"w-32"}>{item.patreon ? <span className={"text-green-600"}>Yes</span> : <span className={"text-blue-600"}>No</span>}</div>
                            <div className={"w-32"}>{item.staff ? <span className={"text-green-600"}>Yes</span> : <span className={"text-blue-600"}>No</span>}</div>
                        </Link>
                    ))}
                </div>
            ) : (
                <div className={"flex h-screenPadding"}>
                    <div className={"m-auto w-full sm:w-5/6 md:w-4/5 lg:w-128 mx-auto text-center"}>
                        <h1>Search for a user</h1>
                        <div className={"text-gray-400 text-lg pt-4 pb-10"}>You can search by name, email address or by their snowflake.</div>
                        <form onSubmit={toggleSearch}>
                            <input type={"text"} className={"form-general w-full"} placeholder={"Search"} value={search} onChange={(e) => setSearch(e.target.value)} required />
                        </form>
                    </div>
                </div>
            )}
        </>
    )
}

export default Search;
