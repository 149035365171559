import {FC, useEffect, useState} from 'react';
import { Redirect } from 'react-router-dom';
import Config from "../../../Config";
import {toast} from "react-toastify";
import {Player, PlayerAuth, PlayerFlag, PlayerLog, PlayerMatch} from "../../../interfaces/UserInterface";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import Loading from "../../Loading";

interface UserProps {
    match: {
        params?: {
            name: string;
        }
    }
}

const User:FC<UserProps> = (props) => {

    const { params } = props.match;
    const [ redirect, setRedirect ] = useState('');
    const [ loaded, setLoaded ] = useState<boolean>(false);
    const [ user, setUser ] = useState<Player | null>(null);
    const [ auth, setAuth ] = useState<PlayerAuth | null>(null);
    const [ logs, setLogs ] = useState<PlayerLog[]>([]);
    const [ flags, setFlags ] = useState<PlayerFlag[]>([]);
    const [ matches, setMatches ] = useState<PlayerMatch[]>([]);

    const [ actionModal, setActionModal ] = useState<boolean>(false);
    const [ actionType, setActionType ] = useState<string>('');
    const [ message, setMessage ] = useState<string>('');

    useEffect(() => {
        fetch(`${Config.apiUrl}/admin/v2/player/view?id=${params?.name}`, {
            mode: 'cors',
            credentials: 'include'
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error) {
                    toast.error(response.error);
                    setRedirect('/');
                } else {
                    setLoaded(true);
                    setUser(response.profile);
                    setAuth(response.auth);
                    setLogs(response.logs);
                    setFlags(response.flags);
                    setMatches(response.results);
                }
            })
            .catch((e) => toast.error(`Unknown error occurred, please try again later!`));
    }, [params?.name]);

    const submitAction = (extendedAction?: string, extendedMessage?: string) => {

        fetch(`${Config.apiUrl}/admin/v2/player/action?id=${user?.playerId}`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ playerId: user?.playerId, type: extendedAction || actionType, message: extendedMessage || message })
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error)
                    toast.error(response.error);
                else {
                    toast.success("Action completed!");
                    window.location.reload();
                }
            })
            .catch(() => toast.error(`Unknown error occurred, please try again later!`));
    }

    return (
        redirect
            ? <Redirect to={redirect} />
            : loaded ? (
                <>
                    <div className={`z-50 inset-0 h-screen flex fixed bg-black bg-opacity-50 ${actionModal ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition ease-in-out duration-300`}>
                        <div className={"px-10 m-auto w-full lg:w-128"}>
                            <div className={"bg-gray-800 rounded-2xl p-8 relative"}>
                                <button type={"button"} onClick={() => setActionModal(false)} className={"absolute top-0 right-0 mt-4 mr-4 focus:outline-none text-gray-400 hover:text-gray-600 transition ease-in-out duration-300"}>
                                    <FontAwesomeIcon icon={faTimes} size={"2x"} />
                                </button>
                                <div className={"text-xl pb-4 uppercase font-semibold text-white"}>{actionType.toUpperCase()} Player</div>
                                <div className={"text-white text-base font-semibold pb-3"}>{actionType === 'currency' ? 'Currency (number only)' : actionType === 'item' ? 'Item Id' : 'Message'}</div>
                                <input type={"text"} className={"form-search w-full block"} value={message} onChange={(e) => setMessage(e.target.value)} />
                                <button type={"button"} onClick={() => submitAction()} className={"mt-4 btn-sm btn-blue rounded-full"}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className={"flex flex-wrap mb-6 pb-4 border-b border-gray-800"}>
                        <div className={"w-1/2 flex"}>
                            <div className={"w-20"}>
                                <img className={"w-full h-20 p-1 border-2 border-orange-400 rounded-full"} src={user?.avatarSrc} alt={user?.name} />
                            </div>
                            <div className={"w-auto pl-3 my-auto"}>
                                <h1 className={"text-white normal-case"}>{user?.name}<span className={"text-gray-400"}>#{user?.discriminator}</span></h1>
                                <div className={"text-gray-400 text-sm"}>
                                    Joined {moment.unix(user?.created || 0).fromNow()}
                                </div>
                            </div>
                        </div>
                        <div className={"w-auto my-auto flex gap-x-4 mt-2"}>
                            {user?.banned
                                ?  <button className={"btn-sm btn-red rounded-full"} onClick={() => submitAction('unban', '')}>Unban</button>
                                : (
                                    <>
                                        <button className={"btn-sm btn-blue rounded-full"} onClick={() => { setActionType('currency'); setActionModal(true); }}>Give Currency</button>
                                        <button className={"btn-sm btn-blue rounded-full"} onClick={() => { setActionType('item'); setActionModal(true); }}>Give Item</button>
                                        <button className={"btn-sm btn-yellow rounded-full"} onClick={() => submitAction(user?.patreon ? 'patreon_remove' : 'patreon_add', '')}>{user?.patreon ? 'Remove Patreon' : 'Give Patreon'}</button>
                                        <button className={"btn-sm btn-red rounded-full"} onClick={() => { setActionType('ban'); setActionModal(true); }}>Ban</button>
                                        <button className={"btn-sm btn-red rounded-full"} onClick={() => { setActionType('warn'); setActionModal(true); }}>Warn</button>
                                        <button className={"btn-sm btn-red rounded-full"} onClick={() => { submitAction('reconcile_statistics'); }}>Reconcile Statistics</button>
                                        <button className={"btn-sm btn-red rounded-full"} onClick={() => { submitAction('reconcile_other'); }}>Reconcile Playtime/Experience</button>
                                        {auth?.activationCode && <button className={"btn-sm btn-blue rounded-full"} onClick={() => { setActionType('activate'); setActionModal(true); }}>Activate</button>}
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div className={"flex flex-wrap"}>
                        <div className={"w-full lg:w-1/4 lg:pr-6 pb-10 lg:pb-0"}>
                            <div className={"panel mb-8"}>
                                <div className={"panel-head"}>Flags</div>
                                <div className="panel-body">
                                    <div className={"px-5 h-100 overflow-y-auto"}>
                                        {flags.map((item) => (
                                            <div key={item.created} className={"text-sm text-gray-300 py-2"}>
                                                <div className={"uppercase font-semibold"}>{item.action}</div>
                                                <div className={"text-xs text-gray-500"}>{moment.unix(item.created).fromNow()}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {!user?.staff && (
                                <div className={"panel"}>
                                    <div className={"panel-head"}>Logs</div>
                                    <div className="panel-body">
                                        <div className={"px-5 h-100 overflow-y-auto"}>
                                            {logs.map((item) => (
                                                <div key={item.created} className={"text-sm text-gray-300 py-2"}>
                                                    <div className={"uppercase font-semibold"}>{item.action}</div>
                                                    <div className={"text-xs text-gray-500"}>{item.ipAddress} - {moment.unix(item.created).fromNow()}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={"w-full lg:w-3/4"}>
                            <div className={"table-head justify-between"}>
                                <div className={"w-10"}>
                                    #
                                </div>
                                <div className={"hidden sm:block w-32"}>
                                    Mode
                                </div>
                                <div className={"w-28"}>
                                    PP
                                </div>
                                <div className={"w-32"}>
                                    WPM
                                </div>
                                <div className={"hidden sm:block w-20"}>
                                    Acc %
                                </div>
                                <div className={"hidden sm:block w-20"}>
                                    EXP
                                </div>
                                <div className={"w-32"}>
                                    Date
                                </div>
                            </div>
                            {matches.map((item, index) => (
                                <a key={item.matchId} className={"table-item justify-between"} href={`https://keymash.io/replay/${user?.playerId}/${item.matchId}/`} target="_blank" rel="noopener noreferrer">
                                    <div className={"w-10 font-semibold opacity-50"}>
                                        {index + 1}
                                    </div>
                                    <div className={"hidden sm:block w-32"}>
                                        {item.leaderboardSort.flagId === 0 ? 'Public' : item.leaderboardSort.flagId === 3 ? 'Ranked' : item.leaderboardSort.flagId === 2 ? 'Custom' : 'Ladder'}
                                    </div>
                                    <div className={"w-28"}>
                                        {item.points}pp
                                    </div>
                                    <div className={"w-32"}>
                                        {item.wpm} WPM
                                    </div>
                                    <div className={"hidden sm:block w-20"}>
                                        {item.accuracy}%
                                    </div>
                                    <div className={"hidden sm:block w-20"}>
                                        {item.exp} EXP
                                    </div>
                                    <div className={"w-32"}>
                                        {moment.unix(item.created).fromNow()}
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                </>
            ) : <Loading />
    )
}

export default User;
