import {FC, FormEvent, useState} from "react";
import { toast } from 'react-toastify';
import Logo from "../../logo/Logo";
import Config from "../../../Config";

const Login:FC = () => {
    const [ emailAddress, setEmailAddress ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ pin, setPin ] = useState('');

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        fetch(`${Config.apiUrl}/admin/v2/auth/login`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ emailAddress, password, securityPin: pin })
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error)
                    toast.error(response.error);
                else {
                    window.location.href = '/';
                }
            })
            .catch(() => toast.error(`Unknown error occurred, please try again later!`));
    }

    return (
        <div className={"flex h-screenPadding"}>
            <div className={"w-116 m-auto rounded bg-gray-800 p-10"}>
                <div className={"flex justify-center"}>
                    <Logo />
                </div>
                <form method="post" className={"grid grid-cols-4 gap-4 pt-8"} onSubmit={handleSubmit}>
                    <input type={"email"} className="form-login col-span-full" placeholder={"Email Address"} name={"emailAddress"} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} required={true} autoComplete={"false"} autoFocus={true} />
                    <input type={"password"} className="form-login col-span-3" placeholder={"Password"} name={"password"} value={password} onChange={(e) => setPassword(e.target.value)} required={true} autoComplete={"false"} autoFocus={true} />
                    <input type={"password"} className="form-login col-span-1" placeholder={"PIN"} name={"pin"} value={pin} onChange={(e) => setPin(e.target.value)} required={true} autoComplete={"false"} autoFocus={true} />
                    <div className={"col-span-full"}>
                        <button type={"submit"} className={"block btn-sm btn-default rounded-full"}>Login</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login;
