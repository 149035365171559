import { FC } from 'react';
import logo from '../../assets/logo-text-light.svg';
import logoIcon from '../../assets/logo-icon.svg';

interface LogoProps {
    small?: boolean;
}

const Logo: FC<LogoProps> = (props) => {

  return (
      <>
        <img src={logo} className={"hidden lg:block w-full h-auto"} alt={"Logo"} />
        <img src={logoIcon} className={"lg:hidden w-full h-auto"} alt={"Logo"} />
      </>
  );
};

export default Logo;
